<template>
  <div v-if="v_id != null && v_id != ''">
    <div v-if="v_id != null && v_id != ''">
      <youtube
        :video-id="v_id"
        :player-vars="playerVars"
        @ready="onReady"
        @playing="playingVideo"
        @ended="Ended"
        ref="player"
      ></youtube>
    </div>
  </div>
</template>

<script>
export default {
  props: ["v_id"],
  data() {
    return {
      playerVars: {
        autoplay: 0,
        title: 0,
        color: "white",
        modestbranding: 1,
        showinfo: 0,
        iv_load_policy: 0,
        cc_load_policy: 0,
        fs: 1,
        rel: 0,
        showshare: 0,
        cc_lang_pref: 0,
        widget_referrer: 0,
        captions: 0,
      },
    };
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    player() {
      if (this.$refs.player) {
        return this.$refs.player.player;
      }
    },
  },
  methods: {
    onReady() {
      this.player = false;
    },
    play() {
      this.player.play();
      this.playing = true;
    },
    playingVideo() {
      this.$emit("Start");
    },
    Ended() {
      this.$emit("Ended");
    },
  },
};
</script>

<style>
/*=================video player css start======================*/
.custom-player {
  border: 1px solid #ccc;
  border-radius: 30px;
  box-shadow: 0px 0px 5px #ccc;
  width: 100%;
}
.custom-player .ytp-iv-logo {
  display: none !important;
}
iframe {
  margin: 0 auto;
  width: 100%;
  position: relative;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}
iframe::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: red;
  z-index: 9999999;
}
/*=================video player css end======================*/
</style>
