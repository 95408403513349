import Vue from "vue";
import VueRouter from "vue-router";
import HomePage from "../views/HomeView.vue";
// Dhamshur Economic Zone
import dZone1 from "../assets/images/p-16.jpg";
import dZone2 from "../assets/images/p-18.png";
import dZone3 from "../assets/images/p-17.png";
import dZone4 from "../assets/images/dzone-4.png";
import dZone5 from "../assets/images/dzone-5.png";
import dZone6 from "../assets/images/dzone-6.jpg";
import dZone7 from "../assets/images/dzone-7.png";
import dZone8 from "../assets/images/d-zone8.png";
import dZone9 from "../assets/images/dzone-9.png";
//Marriott
import MarriLogo from "../assets/images/M_Marriott_logo.png";
import MarriLogo1 from "../assets/images/Marriott-International-Inc-ogo.png";
import M1 from "../assets/images/p-13.jpg";
import M2 from "../assets/images/p-14.png";
import M3 from "../assets/images/M-1.png";
import M4 from "../assets/images/M-2.png";
import M5 from "../assets/images/M-3.png";
import M6 from "../assets/images/M-4.png";
import M7 from "../assets/images/M-6.png";
//Le  Meridien Dhaka
import l1 from "../assets/images/p-1.jpg";
import l2 from "../assets/images/p-2.jpg";
import l3 from "../assets/images/p-3.jpg";
import leLogo from "../assets/images/LM-Dhaka-Logo.png";

//Luxury villa
import v1 from "../assets/images/p-4.jpg";
import v2 from "../assets/images/p-5.jpg";
import v3 from "../assets/images/p-6.jpg";
import v4 from "../assets/images/p-7.png";
import v5 from "../assets/images/p-8.jpg";
//Le Meridian service suite and executive apartment
import s1 from "../assets/images/p-9.jpg";
import s2 from "../assets/images/p-10.jpg";
import s3 from "../assets/images/p-11.jpg";
//He Muslin A Luxury  Collection
import lu1 from "../assets/images/Marriott-International-Inc-ogo.png";
import lu2 from "../assets/images/Luxury-Collection-logo.png";
//Healthcare Franchise with Commercial Complex
// import h1 from '../assets/images/p-12.jpg'
//the muslin collection
import muslin1 from "../assets/images/The_Luxury_Collection_Resort/The-Luxury-Collection_1.jpg";
import muslin2 from "../assets/images/The_Luxury_Collection_Resort/The-Luxury-Collection_2.jpg";
import muslin3 from "../assets/images/le_meridien_resort1-759x506.jpg";

// csr picture
import csr1 from "../assets/images/csr/c1.jpg";
import csr2 from "../assets/images/csr/c2.jpg";
import csr3 from "../assets/images/csr/c3.jpg";
import csr4 from "../assets/images/csr/c4.jpg";
import csr5 from "../assets/images/csr/c5.jpg";
import csr6 from "../assets/images/csr/c6.jpg";
import csr7 from "../assets/images/csr/c7.jpg";
import csr8 from "../assets/images/csr/c8.jpg";
import csr9 from "../assets/images/csr/c9.jpg";
import csr10 from "../assets/images/csr/c10.jpg";
import csr11 from "../assets/images/csr/c11.jpg";
import csr12 from "../assets/images/csr/c12.jpg";
import csr13 from "../assets/images/csr/ce1.jpg";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomePage,
  },
  {
    path: "/contact",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ContactView.vue"),
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // },
  {
    path: "/notice",
    name: "Notice",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/NoticeView.vue"),
  },
  {
    path: "/price-sensitive",
    name: "Price Sensitive",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PriceSensitive.vue"),
  },
  {
    path: "/prospectus",
    name: "Prospectus",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ProspectusView.vue"),
  },
  {
    path: "/quarterly-reports",
    name: "QuarterlyReports",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/QuarterlyReports.vue"),
  },
  {
    path: "/utilization-reports",
    name: "UtilizationReports",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/UtilizationReports.vue"),
  },
  {
    path: "/mission",
    name: "our mission",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/OurMissionView.vue"),
  },
  {
    path: "/vision",
    name: "our vision",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/OurVisionView.vue"),
  },
  // {
  //   path: '/about-company',
  //   name: 'about company',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutCompanyView.vue')
  // },
  {
    path: "/units-concerns",
    name: "units concerns",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/UnitsConcernsView.vue"),
  },
  {
    path: "/corporate-governance",
    name: "corporate governance",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/CorporateGovernanceView.vue"
      ),
  },
  {
    path: "/board-directors",
    name: "Board Directors",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/BoardDirectorsView.vue"),
  },
  {
    path: "/management-team",
    name: "Management Team",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/ManagementTeamViews.vue"
      ),
  },
  {
    path: "/csr",
    name: "CSR",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/CsrView.vue"),
  },
  {
    path: "/news",
    name: "news",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/NewsView.vue"),
  },
  {
    path: "/dhamshur-economic-zone",
    name: "Dhamshur Economic Zone",
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/projectDetail.vue"),
    props: {
      // <-- props as an Object
      details: {
        text1:
          "Dhamshur Economic Zone Limited, a subsidiary of Best Holdings Limited, has been establishing a private economic zone under the name of Dhamshur Economic Zone, just 65 km away from Hazrat Shahjalal International Airport, Dhaka near the Dhaka-Mymensingh highway. The existence of numerous local and foreign industries makes the region very valuable in terms of economic development and connectivity. The Dhamshur Economic Zone will comprise businesses in power, assembly, light engineering, engineering and electronics, textile and sportswear, pharmaceutical, food and agricultural processing, hospitality franchise, education franchise and private housing sectors.",
        imgs: [
          dZone1,
          dZone2,
          dZone3,
          dZone4,
          dZone5,
          dZone6,
          dZone7,
          dZone8,
          dZone9,
        ],
      },
      linkText: "Dhamshur Economic Zone",
      Parent: "units-concerns",
    },
  },
  {
    path: "/marriott",
    name: "Marriott",
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/projectDetail.vue"),
    props: {
      // <-- props as an Object
      details: {
        text1:
          "Best Hotels Limited, a subsidiary of Best Holdings Limited, has been establishing the prestigious upper upscale hotel, Marriott Bhaluka, just 50 km away from Hazrat Shahjalal International Airport, Dhaka. Marriott Bhaluka is a premium five-star hotel chain of USA’s Marriott International, Inc., designed for those travelling frequently on business and recreational purpose. Here, you will discover excellent facilities, such as exquisite rooms, banquet hall, gymnasium, pool café, swimming pools, spa, bar, specialized dining and many more.",
        imgs: [M1, M2, M4, M5, M6, M6, M3, M7],
        logo: [
          { img: MarriLogo1, webLink: "https://www.marriott.com/default.mi" },
          { img: MarriLogo, webLink: "https://marriott-hotels.marriott.com/" },
        ],
      },
      linkText: "Marriott",
      Parent: "units-concerns",
    },
  },
  {
    path: "/le-meridien-dhaka",
    name: "Le  Meridien Dhaka",
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/projectDetail.vue"),
    props: {
      // <-- props as an Object
      details: {
        text1:
          "Best Holdings Limited has established the exquisite Le Méridien Dhaka, an upper upscale franchise hotel in Bangladesh. Le Méridien Dhaka is a premium 5-star hotel brand of USA’s Marriott International, Inc. The hotel is designed to captivate and stimulate curiosity, revealing an inviting threshold of indulgent amenities and decor of vibrant hues on achromatic tones. A choice of 7 restaurants and bars offer a selection of global cuisine in local flavours alongside key brand programs to excite the creative-minded and culture-seekers. The hotel also features the Explore SpaSM, which is signature to Le Méridien brand, a state-of-the-art fitness centre, and the only rooftop tennis court and a skyline infinity pool on the sixteenth story in the city, reinterpreting luxury to new heights of imagination.",
        imgs: [l1, l2, l3],
        logo: [
          {
            img: leLogo,
            webLink:
              "https://www.marriott.com/en-us/hotels/dacmd-le-meridien-dhaka/overview/",
          },
        ],
      },
      linkText: "Le  Meridien Dhaka",
      Parent: "units-concerns",
      fb: "https://www.facebook.com/LMDhaka/",
      insta: "https://www.instagram.com/lemeridiendhaka/?hl=en",
    },
  },
  {
    path: "/le-meridian-commercial-complex",
    name: "Le Meridian Commercial Complex",
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/projectDetail.vue"),
    props: {
      // <-- props as an Object
      details: {
        text1:
          "Best Hotels Limited, a subsidiary of Best Holdings Limited, has been establishing the prestigious upper upscale hotel, Marriott Bhaluka, just 50 km away from Hazrat Shahjalal International Airport, Dhaka. Marriott Bhaluka is a premium five-star hotel chain of USA’s Marriott International, Inc., designed for those travelling frequently on business and recreational purpose. Here, you will discover excellent facilities, such as exquisite rooms, banquet hall, gymnasium, pool café, swimming pools, spa, bar, specialized dining and many more.",
        imgs: [],
      },
      linkText: "Le Meridian Commercial Complex",
      Parent: "units-concerns",
    },
  },
  {
    path: "/le-meridian-service",
    name: "Le Meridian service suite and executive apartment",
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/projectDetail.vue"),
    props: {
      // <-- props as an Object
      details: {
        text1:
          "Le Meridien Service Suit and Executive Apartments will be constructed on a 148.50 decimal land in Bashundhara R/A, Dhaka. It will be the 1st International chain residence of Marriott International in Bangladesh for long staying guests with very high security and taste of quality. 198 Service Suits shall be owned by Best Holdings Ltd. and 114 Executive Apartments shall be owned by private individuals. The entire property shall be managed and maintained by Marriott International.",
        imgs: [s1, s2, s3],
      },
      linkText: "Le Meridian service suite and executive apartment",
      Parent: "units-concerns",
    },
  },
  {
    path: "/he-muslin-a-luxury",
    name: "The Muslin A Luxury  Collection",
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/projectDetail.vue"),
    props: {
      // <-- props as an Object
      details: {
        text1:
          "Best Hotels Limited, a subsidiary of Best Holdings Limited, has been establishing The Muslin, a Luxury Collection Resort, Bhaluka, less than an hour drive away from the city’s bustle. The Luxury Collection hotels & resorts is an extraordinary luxury brand of USA’s Marriott International, Inc., making The Muslin one of the most magnificent properties in the country. This exquisite resort will be your perfect getaway where you can enjoy all kinds of amenities starting from light and water show, Botanical park, kids’ zone, golf range, Cineplex, shops and more.",
        imgs: [muslin3, muslin1, muslin2],
        logo: [
          { img: lu1, webLink: "https://www.marriott.com/default.mi" },
          { img: lu2, webLink: "https://the-luxury-collection.marriott.com/" },
        ],
      },
      linkText: "The Muslin A Luxury  Collection",
      Parent: "units-concerns",
    },
  },
  {
    path: "/luxury-villa",
    name: "Luxury villa",
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/projectDetail.vue"),
    props: {
      // <-- props as an Object
      details: {
        text: "",
        imgs: [v1, v2, v3, v4, v5],
      },
      linkText: "Luxury villa",
      Parent: "units-concerns",
    },
  },
  // {
  //   path: '/healthcare-franchise',
  //   name: 'Healthcare Franchise with Commercial Complex',
  //   component: () => import(/* webpackChunkName: "about" */ '../components/projectDetail.vue'),
  //   props: {  // <-- props as an Object
  //     details: {
  //       text1: "International chain hospital on 718 decimal land with commercial complex like shopping mall, cineplex etc., office block and residential facilities at Birulia, Pallabi, Dhaka on 6 lane highway. This project will be the 1st of its kind in Bangladesh where all kinds of township facilities shall be provided inside a single area with very high quality life style and up-graded medical facilities.",
  //       imgs:[h1],
  //     },
  //     linkText:'Healthcare Franchise with Commercial Complex',
  //     Parent:'units-concerns'
  //   },
  // },
  // csr section
  {
    path: "/le-meridien-dhaka-and-four-points-by-sheraton-dhaka-jointly-hosted-run-to-give-on-24-september-2017/",
    name: "Le Méridien Dhaka and Four Points by Sheraton Dhaka jointly hosted ‘Run to Give’ on 24 September 2017",
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/projectDetail.vue"),
    props: {
      // <-- props as an Object
      details: {
        text: [
          "Dhaka, 25 Sep 2017 – Le Méridien Dhaka and Four Points by Sheraton jointly hosted Marriott Internationals ‘Run to Give’ charity run in support of their charity partner Room to Read Bangladesh. This is an annual charity run which took place over 206 properties across Asia Pacific on September 24, 2017.",
          "The parent company Marriott International has initiated the annual ‘Run to Give’ successfully since 2014, binging in hotel associates together to organize runs in different cities to support local charities. This year over 250 participants from both the property participated at the event. To make it a great success, the team at Le Méridien Dhaka and Four Points by Sheraton Dhaka jointly raised funds for their charity partner with the support of their sponsors, partners and their associates.",
          "The marathon held in Dhaka comprised of 10 km starting from ICCB (International Convention City Bashundhara) to Kanchon Bridge and back to ICCB (International Convention City Bashundhara) along the Pulbachal express way. The two hour marathon was flagged off by Legendary Musician, Shafin Ahmed, along with the presence of the two General Managers, Charity Partner, Sponsors and Partners.",
          "The main sponsors for the event were American & Efird (Bangladesh) Ltd., Padma Textiles Ltd., ZXY International, Jaroms Industries Ltd., ISMT and HRM Sourcing ltd. While the event partners for the charity run were Radio Shadhin, The Daily Star, Pepsi, Polar and Ruchi.",
          "‘Run to Give’ is a key event in Asia Pacific under the company’s ‘TakeCare’ movement, which aims to encourage associates to live their best life by promoting physical, emotional and spiritual wellbeing and creating strong team synergy, while reinforcing the company’s core values of “We Serve Our World”. Constantinos S. Gavriel, General Manager, Le Méridien Dhaka, stated, “It’s the second year of our operation here in Bangladesh and we not only remain firm to work towards economic development but also towards the social development in the community we serve. With the increasing popularity of our brand in Bangladesh market, our efforts to serve the world positively remains strong. We hope our little contribution will make a difference in the lives of the children at Room to Read Bangladesh”.",
        ],
        imgs: [csr1, csr2],
      },
      linkText:
        "Le Méridien Dhaka and Four Points by Sheraton Dhaka jointly hosted ‘Run to Give’ on 24 September 2017",
      Parent: "csr",
    },
  },
  {
    path: "/le-meridien-dhaka-hosts-iftar-mahfil-for-children",
    name: "Le Méridien Dhaka Hosts Iftar Mahfil for Orphan Children",
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/projectDetail.vue"),
    props: {
      // <-- props as an Object
      details: {
        text: [
          " [Dhaka, May 24, 2018] To honor and observe the teaching of the Holy Ramadan, Amin Ahmad, Chairman, Best Holdings Limited (Owning Company of Le Méridien Dhaka), hosted an Iftar Mahfil for the children of Baitul Aman Housing Society Madrasa and Orphanage on May 23, 2018 at the Grand Ballroom of Le Méridien Dhaka. A.K.M Shahjahan Kamal, Honourable Minister, Ministry of Civil Aviation and Tourism attended the event as the Chief Guest. Md. Mohibul Haque, Secretary in Charge, Ministry of Civil Aviation and Tourism was also present as the Special Guest.",
          "Ramadan is the month of blessings where people spiritually get connected with each other. Every year, Le Méridien Dhaka hosts Iftar with children and encourage the youths to greater heights of inspiration. Preserving that belief this year also the hotel management heartily welcomed 150 children of Baitul Aman Housing Socity Madrasa and Orphanage. During a healthy and delightful Iftar Mahfil, guests have shared inspiring stories which created an aura of religious inspiration.",
          "The management of the hotel expressed their gratitude and happiness for hosting such an endeavor for these beautiful children from the orphanage. Along with all the traditional and cultural celebrations, this was a soulful effort which brought the confidence of solidarity among everyone.",
        ],
        imgs: [csr3, csr4, csr5, csr6, csr7, csr8, csr9, csr10, csr11, csr12],
      },
      linkText: "Le Méridien Dhaka Hosts Iftar Mahfil for Orphan Children",
      Parent: "csr",
    },
  },
  {
    path: "/le-meridien-dhaka-hosts-daylong-event-on-women-empowerment/",
    name: "Le Méridien Dhaka hosts daylong event on Women Empowerment",
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/projectDetail.vue"),
    props: {
      // <-- props as an Object
      details: {
        text: [
          "[Dhaka, March 31, 2018] To uplift the spirit of women empowerment, Le Méridien Dhaka arranged a daylong event titled ‘Le Méridien Dhaka Presents Colours of Women’ on March 31, 2018 in collaboration with ColoursFM 101.6. The theme of the event was ‘Women Empowering Women’, where 24 artist from all over the country joined hands to enlighten the life’s of 18 struggling women through their art. The daylong event featured Live Art session by renowned artists, panel discussion and fundraising session for struggling women. The Indonesian Ambassador to Bangladesh, H.E. Mrs. Rina P. Soemarno, attended the event as Chief Guest.",
          "The event started earlier in the morning with Live Art session by renowned artists like Kanak Chapa Chakma, Kuhu Plamondon, Naima Haque, Fareha Zeba, Bipasha Hayat, and many others. They have painted for this charitable occasion with a view to showcasing their artistic charisma and provide financial empowerment to the struggling women. The painted artworks had been sold out at the fundraising session towards the end of the day. The money collected by selling the artworks has been distributed among the 18 women who have been selected from across the country.",
          "A very intriguing session of panel discussion was conducted after the Live Art session. The panellists of the discussion were: Tasaffy Hossain, Founder, Bonhishikha-Unlearn Gender; Zareen Mahmud Hossein, Founder, HerStory Foundation; Nooruddin Chowdhury, Country Manager, DHL Global Forwarding Bangladesh and Aftab Mahmud Khurshid, CMO, Bengal Group. The panel discussion was moderated by Asna Towfiq, Consultant, United Nations Foundation.",
          "Among the Special Guests, Sara Zaker, Executive Vice-Chairperson, Asiatic 3 sixty; Farah Kabir, Country Director, ActionAid International; Geeteara Safiya Choudhury, Chairman, ColoursFM 101.6 attended the event.",
          "“The event was an artistic, social and charitable endeavor of empowering women. From the very beginning, we were immensely enthusiastic, concerned and supportive about women empowerment which also reflects as one of the core strategies of Marriott International. We believe this event will be another signature effort of our commitment towards this global issue” stated by the management of Le Méridien Dhaka.",
        ],
      },
      linkText: "Le Méridien Dhaka hosts daylong event on Women Empowerment",
      Parent: "csr",
    },
  },
  {
    path: "/employing-the-unemployed",
    name: "Employing the Unemployed",
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/projectDetail.vue"),
    props: {
      // <-- props as an Object
      details: {
        text: [
          "All the business initiatives taken by Best Holdings Limited was for a greater cause, that it to create employment for all and help them to live a better life.",
        ],
        imgs: [csr13],
      },
      linkText: "Employing the Unemployed",
      Parent: "csr",
    },
  },
  {
    path: "/going-green",
    name: "Going Green",
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/projectDetail.vue"),
    props: {
      // <-- props as an Object
      details: {
        text: [
          "There is a variety of reasons to go green, but most come back to supply and demand. We have a limited amount of resources available and more and more people using them up. If we want our future generations to enjoy the same standard of living we’ve experienced, we need to take action.",
          "Green building is a great place to start, as buildings consume 14% of potable water, 40% of raw materials, and 39% of energy in the United States alone (according to the US Green Building Council). That’s 15 trillion gallons of water and 3 billion tons of raw materials each year! If that’s not enough to convince you, here are some other reasons to go green:",
        ],
        subhead: "For The Environment",
        details1: [
          "If  we want to make the world a better place, then we will have to start implementing green practices into our home or office which can help reduce waste, conserve natural resources, improve both air and water quality, and protect ecosystems and biodiversity.",
          "“Going Green” means practicing an environmentally friendly and ecologically responsible lifestyle as well as making decisions to help protect the environment and sustain natural resources. There are lots of reasons to consider going green—too much trash, greenhouse gases, air and water pollution, damage to the ozone layer, and saving money. For example, switching all the light bulbs in a home from conventional incandescent light bulbs to compact fluorescent light (CFL) bulbs could save about $40 over the life of the bulb. Other examples include:",
        ],
        list: [
          "Turning your thermostat down two degrees in winter and up two degrees in summer.",
          "Making sure your walls and ceilings are well insulated.",
          "Replacing bathroom and kitchen faucets with low-flow models.",
        ],
        subhead1: "We Make Greener Product Choices",
        details2: [
          "Buying only what we need is the first step to go green, but when you buy, looking for greener products and using products in ways that respect the environment can have a big impact — on the health of our family, pets and the planet.",
          "The U.S. Environmental Protection Agency (EPA) has a green products web portal to help us navigate the complex world of green products. We use this portal to find links and information related to greener products from EPA and other sources.",
          "The EPA has a number of eco-labeling partnership programs to help you identify greener, safer, and more efficient products. The standards behind these labels are based on scientific expertise and use the best available data.",
        ],
        list1: [
          "EnergyStar – for energy efficient electronics and appliances",
          "WaterSense – water efficient products",
          "Design for the Environment (DfE) – safer household cleaners and other products. DfE allows products that have been determined to be safer for human health and the environment and effective to carry the DfE label.",
          "SmartWay Certified Vehicle – cleaner, more fuel efficient cars and trucks",
        ],
        details3: [
          "By making greener product choices we are saving money on utilities and fuel, supporting companies that are driving change and most importantly — we are joining millions of people helping to protect public health and the environment.",
          "We  also choose to buy organic or locally produced food and eco-friendly clothing.",
        ],
        subhead2: "Reusing and Recycling",
        details4: [
          "Along with buying greener products, we  make a big impact by using the products we buy in ways that respect the environment by: using fewer products and following instructions for product use; conserving energy, water, and materials; recycling items made of materials such as glass, metal, plastic, or paper or disposing of products properly.",
        ],
      },
      linkText: "Going Green",
      Parent: "csr",
    },
  },
  {
    path: "/charity-run-by-le-meridien-dhaka",
    name: "Charity run by Le Meridien Dhaka",
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/projectDetail.vue"),
    props: {
      // <-- props as an Object
      details: {
        text: [
          "The Executive Officials and Staff of Le Meridien Hotel and the Four points Dhaka organized a Charity run for the poor and under privileged children.",
        ],
      },
      linkText: "Charity run by Le Meridien Dhaka",
      Parent: "csr",
    },
  },
  {
    path: "/housing-facilities-for-underprivileged",
    name: "Housing Facilities for Underprivileged",
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/projectDetail.vue"),
    props: {
      // <-- props as an Object
      details: {
        text: [
          "Best Holdings Limited has laid out a great plan of serving the poor and the homeless by establishing a rural housing project at the outskirts of Noakhali, locally known as Subarnachar.",
          "The Company itself owns a huge amount of cultivable land which is currently under development.",
          "The homes are built with bricks and going to be tin shed. There are two rooms for one selective family with a kitchen and a washroom attached to the courtyard.",
          "The housing project is being constructed and it is expected that the lucky owners of these pucca houses will be rewarded with a permanent residence and also employment by taking care of the Niloy  Agro Fisheries and Hatchery Project.",
        ],
        text3:
          "The housing project is being constructed and it is expected that the lucky owners of these pucca houses will be rewarded with a permanent residence and also employment by taking care of the Niloy  Agro Fisheries and Hatchery Project.",
      },
      linkText: "Housing Facilities for Underprivileged",
      Parent: "csr",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
