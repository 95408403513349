<template>
  <v-layout wrap>
    <v-container fluid class="homeSlider">
      <v-carousel :show-arrows="true" hide-delimiters cycle height="605px">
        <v-carousel-item v-for="(item, i) in sliderImg" :key="i">
          <v-img :src="item.img" height="605px"></v-img>
        </v-carousel-item>
      </v-carousel>
    </v-container>
    <!-- <v-row><CommonHero :image="image" border="border" head="Find Your Perfect Place"></CommonHero></v-row> -->
    <v-container>
      <v-row class="welcome-sec">
        <v-col md="5" lg="5" cols="12" class="wc-head">
          <h1>
            Welcome to<br />
            Best Holdings Limited
          </h1>
        </v-col>
        <v-col md="7" lg="7" cols="12">
          <p class="text-justify">
            Best Holdings Limited (BHL) is a pioneer in Bangladesh’s
            construction sector and this sector is significantly contributing to
            the national infrastructure development.
          </p>
          <p class="text-justify">
            BHL has built its house of expertise in technology, exclusively to
            meet complex structures like Extra dozed PC Box Girder Bridge,
            Pre-Stress Concrete Girder Bridges, RCC Bridges, Culverts,
            Irrigation Projects, Roads and Highways constructions development
            and building of high rise complexes construction, Five-star Hotel
            Construction, Luxurious property development and other industrial
            establishments.
          </p>
          <p class="text-justify">
            Our commitment towards our valued clients is reinforced by many
            decades of experience working as a partner with the government,
            local authorities, public beneficial utilities and capital intensive
            industrial projects without compromising the quality of standards
            and deadlines.
          </p>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="significant">
      <v-row
        :style="
          $vuetify.breakpoint.smAndDown
            ? 'padding-top:0px;text-align: left;'
            : 'padding-top:50px;text-align: left;'
        "
      >
        <v-col md="6">
          <!-- <v-carousel :show-arrows="false" style="border-radius:35px;height:445px">
              <v-carousel-item
                v-for="(item,i) in items"
                :key="i"
                :src="item.img"
              ></v-carousel-item>
        </v-carousel> -->
          <v-img
            :style="
              $vuetify.breakpoint.smAndDown
                ? 'margin-top:0px;border-radius:25px;'
                : 'border-radius:35px;margin-top:70px;'
            "
            src="@/assets/images/significant.jpg"
            :height="$vuetify.breakpoint.mdAndDown ? '470' : '370'"
          ></v-img>
        </v-col>
        <v-col md="6">
          <v-list class="text-left">
            <v-list-item class="pb-5"
              ><h2>Our significant Projects:</h2></v-list-item
            >
            <v-list-item
              v-for="s in significant"
              :key="s.text"
              :class="s.pl"
              style="
                align-items: self-start;
                text-align: justify;
                margin-bottom: 10px;
                min-height: 30px;
              "
            >
              <v-icon
                class="mr-2"
                color="#181818"
                text
                v-if="s.icon"
                :size="s.size"
                >{{ s.icon }}</v-icon
              >{{ s.text }}
            </v-list-item>
            <!-- <v-list-item class="pt-5">
              <v-btn
            depressed
            color="#ED1C24"
            class="mt-5 white--text"
            style="padding: 14px 64px;border-radius: 15px;height:48px"
          >
            More About Us
          </v-btn>
            </v-list-item> -->
          </v-list>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row
        :style="
          $vuetify.breakpoint.smAndDown
            ? 'padding-top:50px;'
            : 'padding-top:150px;'
        "
      >
        <v-col
          md="6"
          sm="12"
          class="project-card"
          :class="$vuetify.breakpoint.smAndDown ? 'order-2' : ''"
        >
          <v-card>
            <v-card-title
              style="
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 36px;
                line-height: 50px;
                color: #181818;
              "
              >Our Projects</v-card-title
            >
            <v-card-text class="text-justify"
              ><strong>Le Meridien Dhaka:</strong> Best Holdings Limited has
              established the exquisite Le Méridien Dhaka, an upper upscale
              franchise hotel in Bangladesh. Le Méridien Dhaka is a premium
              5-star hotel brand of USA’s Marriott International, Inc. The hotel
              is designed to captivate and stimulate curiosity, revealing an
              inviting threshold of indulgent amenities and decor of vibrant
              hues on achromatic tones. A choice of 7 restaurants and bars offer
              a selection of global cuisine in local flavours alongside key
              brand programs to excite the creative-minded and culture-seekers.
              The hotel also features the Explore SpaSM, which is signature to
              Le Méridien brand, a state-of-the-art fitness centre, and the only
              rooftop tennis court and a skyline infinity pool on the sixteenth
              story in the city, reinterpreting luxury to new heights of
              imagination.</v-card-text
            >
            <v-btn
              depressed
              color="#ED1C24"
              class="mt-5 white--text"
              to="/units-concerns"
            >
              View all
            </v-btn>
          </v-card>
        </v-col>
        <v-col
          md="6"
          sm="12"
          class="project-carousel"
          :class="$vuetify.breakpoint.smAndDown ? 'order-1' : ''"
        >
          <v-carousel
            hide-delimiters
            :style="
              $vuetify.breakpoint.smAndDown
                ? 'border-radius:25px;'
                : 'border-radius:35px;'
            "
            cycle
            :height="$vuetify.breakpoint.mdAndDown ? '600' : '500'"
          >
            <v-carousel-item
              class="text-right ourProjectSlider"
              v-for="(item, i) in ourProject"
              :key="i"
            >
              <router-link :to="item.link">
                <v-img
                  :src="item.img"
                  :height="$vuetify.breakpoint.mdAndDown ? '600' : '500'"
                >
                  <p>{{ item.text }}</p>
                </v-img></router-link
              >
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>
    </v-container>
    <!-- <v-row class="initiatives">
      <v-col md="12"><h1 class="text-center">Our Initiatives</h1></v-col>
      <v-col md="2" v-for="initiative in initiatives" :key="initiative.image">
        <v-img :src="initiative.image"></v-img>
      </v-col>
    </v-row> -->

    <v-container>
      <v-row class="gallery">
        <v-col md="6">
          <h1 class="text-left">Gallery</h1>
        </v-col>
        <v-col md="6" class="d-flex justify-end">
          <v-icon @click="showPrev">mdi-arrow-left</v-icon>
          <v-icon @click="showNext">mdi-arrow-right</v-icon>
        </v-col>
        <v-col md="12">
          <CoolLightBox
            :items="Gallarys"
            :index="index"
            :effect="'fade'"
            @close="index = null"
          >
          </CoolLightBox>
          <VueSlickCarousel v-bind="settings" ref="carousel">
            <div class="pr-3" v-for="(image, i) of Gallarys" :key="i">
              <div class="images-wrapper">
                <v-img
                  class="image"
                  contain
                  :src="image"
                  @click="index = i"
                ></v-img>
              </div>
            </div>
          </VueSlickCarousel>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <h1 class="text-center pb-5">Press</h1>
      <v-row class="Latest-realeses">
        <v-col md="4" sm="12" v-for="realese in news" :key="realese.text">
          <v-card
            class="mx-auto text-left"
            :height="$vuetify.breakpoint.smAndDown ? '750px' : '750px'"
          >
            <VideoPlayer v_id="s9GChnyQ1s8" v-if="realese.videoId" />
            <v-img
              :src="realese.image"
              height="360px"
              v-if="!realese.videoId"
              :contain="realese.contain ? true : false"
            >
            </v-img>
            <v-card-subtitle class="pb-0" v-if="realese.date">
              Released: {{ realese.date }}
            </v-card-subtitle>
            <v-card-title style="font-size: 14px"
              ><b>{{ realese.heading }}</b></v-card-title
            >
            <v-card-text class="text--primary">
              <span style="font-size: 14px">{{ realese.text }}</span>
            </v-card-text>

            <v-card-actions>
              <v-btn color="#ED1C24" text :href="realese.link">
                <strong> Read More</strong>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col md="12" class="justify-center">
          <v-btn depressed color="#ED1C24" class="mt-5 white--text" to="/news">
            View all
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row class="Latest-realeses">
        <v-col md="12 text-center">
          <h1>Latest realeses</h1>
        </v-col>
        <v-col
          md="4  text-left"
          v-for="realese in realeses"
          :key="realese.text"
        >
          <v-card
            class="mx-auto"
            :height="$vuetify.breakpoint.smAndDown ? '600px' : '650px'"
          >
            <v-img :src="realese.image" height="300px"> </v-img>
            <v-card-subtitle class="pb-0">
              Released: {{ realese.date }}
            </v-card-subtitle>
            <v-card-title>{{ realese.heading }}</v-card-title>
            <v-card-text class="text--primary">
              <div>{{ realese.text }}</div>
            </v-card-text>

            <v-card-actions>
              <v-btn color="#ED1C24" text :href="realese.link">
                <strong> Read More</strong>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col md="12" class="justify-center">
          <v-btn depressed color="#ED1C24" class="mt-5 white--text" to="/csr">
            View all
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>

<script>
import CoolLightBox from "vue-cool-lightbox";

import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
// import img from '../assets/images/g1.png'
import initiativeImg from "../assets/images/Initiatives1.png";
// import CommonHero from '@/components/CommonHero.vue'
// realese images are
import img1 from "@/assets/images/csrBlog/run_to_give_2017_01-1024x682.jpg";
import img2 from "@/assets/images/csrBlog/orphan_Ifter_party_09-1024x682.jpg";
import img3 from "@/assets/images/csrBlog/colours_of_women_11-1024x652.jpg";
//banner slider images
import sliderImg1 from "@/assets/images/banner-1.jpg";
import sliderImg2 from "@/assets/images/banner-2.jpg";
import sliderImg3 from "@/assets/images/banner-3.1.jpg";
import sliderImg4 from "@/assets/images/banner-3.jpg";
import sliderImg5 from "@/assets/images/banner-4.jpg";
import sliderImg6 from "@/assets/images/banner-5.jpg";
//project images
import projectImg1 from "@/assets/images/p-1.jpg";
import projectImg3 from "@/assets/images/p-5.jpg";
import projectImg4 from "@/assets/images/p-11.jpg";
// import projectImg5 from '@/assets/images/p-12.jpg'
import projectImg6 from "@/assets/images/p-13.jpg";
import projectImg7 from "@/assets/images/p-16.jpg";
//gallery images Lemeridien-1_Gallery/Le_Meridien_Dhaka-1.jpg
import gallaryImg1 from "@/assets/Gallery/Lemeridien-1_Gallery/g1.jpg";
import gallaryImg2 from "@/assets/Gallery/Lemeridien-1_Gallery/g2.jpg";
import gallaryImg3 from "@/assets/Gallery/Lemeridien-1_Gallery/g3.jpg";
import gallaryImg4 from "@/assets/Gallery/Lemeridien-1_Gallery/g4.jpg";
import gallaryImg5 from "@/assets/Gallery/Lemeridien-1_Gallery/g5.jpg";
import gallaryImg6 from "@/assets/Gallery/Lemeridien-1_Gallery/g6.jpg";
import gallaryImg7 from "@/assets/Gallery/Lemeridien-1_Gallery/g7.jpg";
import gallaryImg8 from "@/assets/Gallery/Lemeridien-1_Gallery/g8.jpg";
import gallaryImg9 from "@/assets/Gallery/Lemeridien-1_Gallery/g9.jpg";
import gallaryImg10 from "@/assets/Gallery/Marriott_Bhaluka-2_Gallery/g1.jpg";
import gallaryImg11 from "@/assets/Gallery/Marriott_Bhaluka-2_Gallery/g2.jpg";
import gallaryImg12 from "@/assets/Gallery/Marriott_Bhaluka-2_Gallery/g3.jpg";
import gallaryImg13 from "@/assets/Gallery/Luxury_Villa-3_Gallery/g1.jpg";
import gallaryImg15 from "@/assets/Gallery/Luxury_Villa-3_Gallery/g2.jpg";
import gallaryImg16 from "@/assets/Gallery/Luxury_Villa-3_Gallery/g3.jpg";
import gallaryImg17 from "@/assets/Gallery/Luxury_Villa-3_Gallery/g4.jpg";
import gallaryImg19 from "@/assets/Gallery/Luxury_Villa-3_Gallery/g5.jpg";
import gallaryImg20 from "@/assets/Gallery/Luxury_Villa-3_Gallery/g6.jpg";
import gallaryImg21 from "@/assets/Gallery/Luxury_Villa-3_Gallery/g7.jpg";
import gallaryImg22 from "@/assets/Gallery/Luxury_Villa-3_Gallery/g8.jpg";
import gallaryImg14 from "@/assets/Gallery/Luxury_Villa-3_Gallery/g9.jpg";
import news1 from "@/assets/images/news_360230_1.jpg";
import VideoPlayer from "@/components/VideoPlayer.vue";

export default {
  components: { VueSlickCarousel, CoolLightBox, VideoPlayer },
  methods: {
    showNext() {
      this.$refs.carousel.next();
    },
    showPrev() {
      this.$refs.carousel.prev();
    },
  },
  data() {
    return {
      index: null,
      sliderImg: [
        { img: sliderImg1 },
        { img: sliderImg3 },
        { img: sliderImg2 },
        { img: sliderImg4 },
        { img: sliderImg5 },
        { img: sliderImg6 },
      ],
      ourProject: [
        {
          img: projectImg1,
          text: "Le Meridien Dhaka",
          link: "/le-meridien-dhaka",
        },
        // {
        //   img:'',text:'Le Meridien Commercial Complex',
        //   link:'/le-meridian-commercial-complex'
        // },
        {
          img: projectImg3,
          text: "Luxury Villa",
          link: "//luxury-villa",
        },
        {
          img: projectImg4,
          text: "Le Meridien Service Suit and executive Apartment",
          link: "/le-meridian-service",
        },
        // {
        //   img:projectImg5,text:'Healthcare Franchise with Commercial Complex',
        //   link:'/healthcare-franchise'
        // },
        {
          img: projectImg6,
          text: "Marriott",
          link: "/marriott",
        },
        {
          img: projectImg7,
          text: "Dhamshur Economic Zone",
          link: "/dhamshur-economic-zone",
        },
      ],
      significant: [
        {
          text: "Major earthworks, land developments, roads and highways developments, construction of Chattogram Port Authority container yard, operation and maintenance of various bridges in Bangladesh under joint collaboration with Grand Saga Sdn Bhd, Malaysia",
          icon: "mdi-checkbox-blank-circle",
          size: "20",
        },
        {
          text: "BHL Group is also undertaking some noteworthy projects such as the following :",
          icon: "mdi-checkbox-blank-circle",
          size: "20",
        },
        {
          text: "A-228-room Marriott Bhaluka – an upper upscale hotel of Marriott International, USA ",
          pl: "pl-11",
          icon: "mdi-checkbox-blank-circle",
          size: "15",
        },
        {
          text: "The Muslin, a Luxury Collection Resort, Bhaluka – a luxury brand hotel of Marriott International, USA",
          pl: "pl-11",
          icon: "mdi-checkbox-blank-circle",
          size: "15",
        },
        {
          text: "Haileybury Bhaluka – a joint venture with Haileybury UK, one of the world’s leading independent schools in the UK ",
          pl: "pl-11",
          icon: "mdi-checkbox-blank-circle",
          size: "15",
        },
        {
          text: "Luxurious private housing",
          pl: "pl-11",
          icon: "mdi-checkbox-blank-circle",
          size: "15",
        },
      ],
      Gallarys: [
        gallaryImg1,
        gallaryImg2,
        gallaryImg3,
        gallaryImg4,
        gallaryImg5,
        gallaryImg6,
        gallaryImg7,
        gallaryImg8,
        gallaryImg9,
        gallaryImg10,
        gallaryImg11,
        gallaryImg12,
        gallaryImg13,
        gallaryImg15,
        gallaryImg16,
        gallaryImg17,
        gallaryImg19,
        gallaryImg20,
        gallaryImg21,
        gallaryImg22,
        gallaryImg14,
      ],
      initiatives: [
        { image: initiativeImg },
        { image: initiativeImg },
        { image: initiativeImg },
        { image: initiativeImg },
        { image: initiativeImg },
        { image: initiativeImg },
      ],

      settings: {
        infinite: true,
        slidesToShow: 4,
        speed: 500,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      realeses: [
        {
          date: "25 Sep 2017",
          image: img1,
          heading: "Le Méridien Dhaka and Four Points by Sheraton Dhaka",
          text: "Dhaka, 25 Sep 2017 – Le Méridien Dhaka and Four Points by Sheraton jointly hosted Marriott Internationals ‘Run to Give’",
          link: "/le-meridien-dhaka-and-four-points-by-sheraton-dhaka-jointly-hosted-run-to-give-on-24-september-2017/",
        },
        {
          date: " May 24, 2018",
          image: img2,
          heading: `Le Méridien Dhaka Hosts Iftar Mahfil for Orphan Children`,
          text: "Dhaka, May 24, 2018 To honor and observe the teaching of the Holy Ramadan, Amin Ahmad, Chairman, Best Holdings Limited",
          link: "/le-meridien-dhaka-hosts-iftar-mahfil-for-children",
        },
        {
          date: "March 31, 2018",
          image: img3,
          heading: "Le Méridien Dhaka hosts daylong event on Women",
          text: "To uplift the spirit of women empowerment, Le Méridien Dhaka arranged a daylong event titled",
          link: "/le-meridien-dhaka-hosts-daylong-event-on-women-empowerment",
        },
      ],
      news: [
        {
          date: "",
          image: "",
          videoId: "s9GChnyQ1s8",
          heading:
            "ভালুকায় ৫ তারকা হোটেল, বিশ্বমানের স্কুলে বড় বিনিয়োগ বিএসএল হাউজের",
          text: "ময়মনসিংহের ভালুকায় ৫ তারকা হোটেল মেরিয়ট, লাক্সারি রিসোর্ট ও ভিলা, বিশ্বমানের ব্রিটিশ আবাসিক স্কুল হেলিবেরি, ১ হাজার শয্যার হাসপাতালসহ অর্থনৈতিক অঞ্চল গড়ে তুলছে বেস্ট হোল্ডিংস লিঃ।",
          link: "https://www.youtube.com/watch?v=s9GChnyQ1s8",
        },

        {
          date: "",
          image: news1,
          heading: `আমরা কোনো তথ্য গোপন করিনি কোনো বিনিয়োগকারী ঠকবেন না`,
          text: "প্রাথমিক গণপ্রস্তাবের (আইপিও) মাধ্যমে বুক বিল্ডিং পদ্ধতিতে পুঁজিবাজারে তালিকাভুক্তির জন্য সম্প্রতি বিডিংয়ের অনুমোদন পেয়েছে বেস্ট হোল্ডিংস লিমিটেড। ",
          link: "https://bonikbarta.net/home/news_description/360230/%E2%80%98%E0%A6%86%E0%A6%AE%E0%A6%B0%E0%A6%BE-%E0%A6%95%E0%A7%87%E0%A6%BE%E0%A6%A8%E0%A7%87%E0%A6%BE-%E0%A6%A4%E0%A6%A5%E0%A7%8D%E0%A6%AF-%E0%A6%97%E0%A7%87%E0%A6%BE%E0%A6%AA%E0%A6%A8-%E0%A6%95%E0%A6%B0%E0%A6%BF%E0%A6%A8%E0%A6%BF-%E0%A6%95%E0%A7%87%E0%A6%BE%E0%A6%A8%E0%A7%87%E0%A6%BE-%E0%A6%AC%E0%A6%BF%E0%A6%A8%E0%A6%BF%E0%A7%9F%E0%A7%87%E0%A6%BE%E0%A6%97%E0%A6%95%E0%A6%BE%E0%A6%B0%E0%A7%80-%E0%A6%A0%E0%A6%95%E0%A6%AC%E0%A7%87%E0%A6%A8-%E0%A6%A8%E0%A6%BE%E2%80%99",
        },
        {
          date: "",
          image:
            "https://www.tbsnews.net/sites/default/files/styles/infograph/public/images/2023/11/11/p_7-lead-12-nov-2023.jpg",
          heading: `How Best Holdings won the hard times, built assets for premium pricing`,
          text: "Keep building quality assets that enable you to charge premium prices and generate incremental revenue over decades; this is the philosophy behind the rise of Best Holdings Ltd as Bangladesh's most premium hospitality operator",
          link: "https://www.tbsnews.net/economy/stocks/how-best-holdings-won-hard-times-built-assets-premium-pricing-737654",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.row {
  margin: 0px -15px;
}
.v-list-item,
p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000de;
  margin: 0 !important;
}
.homeSlider {
  padding-bottom: 50px;
  /* .v-window{
    min-height: 100%;
    .v-image{
      height:100%!important;
    }
  } */
  /* .v-image{
    height:100%;
    width:100%;
  } */
  img {
    height: 100%;
    width: 100%;
  }
}
.welcome-sec {
  padding-top: 0px;
  .wc-head {
    border-right: 3px solid #ed1c24;
    text-align: left;
  }
  h1 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 36px;
    line-height: 50px;
    color: #181818;
  }
  p {
    color: #000000de;
    padding-bottom: 10px;
  }
}
.significant {
  h2 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #181818;
  }
}
.project-card {
  margin-right: -40px;
  margin-top: 54px;
  z-index: 99;
  .v-card {
    padding: 10px 20px 20px 20px;
    text-align: center;
    border-radius: 20px !important;
    .v-card__title {
      justify-content: center;
    }
    .v-card__text {
      text-align: center;
      color: #000000de !important;
      font-size: 16px;
    }
    .v-btn {
      padding: 14px 54px;
      border-radius: 15px;
      height: 48px;
    }
  }
}
.project-carousel {
  .ourProjectSlider {
    .v-image {
      position: relative;
      &::after {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(24, 24, 24, 0.4);
        z-index: 1;
      }
    }
    .v-responsive__content {
      z-index: 9;
    }
    p {
      color: #fff;
      padding-right: 40px;
      padding-top: 40px;
    }
  }
  .v-window {
    .v-window__next {
      background: transparent;
      button {
        background: rgba(255, 255, 255, 0.2);
      }
    }
    .v-window__prev {
      background: transparent;
      right: 0;
      text-align: right;
      top: 60%;
      button {
        background: rgba(255, 255, 255, 0.2);
      }
    }
  }
}
.gallery {
  padding-top: 150px;
  // padding-bottom: 150px;
  h1 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 36px;
    line-height: 50px;
    color: #181818;
  }
  .v-icon {
    margin-right: 20px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #626262;
    color: #fff;
  }

  /* .slick-center.slick-current .v-responsive{
    height:30px !important;
  } */
}
/* .initiatives{
    padding:100px 140px;
    background:#FEF4F4;
  } */
.Latest-realeses {
  padding-top: 0px;
  h1 {
    font-family: "Poppins", sans-serif;
    color: #181818;
    font-size: 36px;
    line-height: 50px;
    font-weight: 600;
    padding-bottom: 30px;
  }
  .v-card__title {
    font-family: "Poppins", sans-serif !important;
    font-size: 18px;
    padding-bottom: 0px;
  }
  p,
  .v-card__text {
    font-family: "Poppins", sans-serif !important;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    padding-bottom: 20px;
  }
  .v-card {
    border-radius: 25px !important;
    position: relative;
    .v-btn::before {
      background: transparent !important;
    }
    .v-card__actions {
      position: absolute;
      bottom: 20px;
      left: 0;
    }
  }
}
@media only screen and (max-width: 1600px) {
  .project-card {
    margin-right: -40px;
    margin-top: 15px !important;
    z-index: 99;
    .v-card {
      padding: 10px 10px 20px 10px;
      text-align: center;
      border-radius: 20px !important;
      .v-card__title {
        justify-content: center;
      }
      .v-card__text {
        text-align: center;
      }
      .v-btn {
        padding: 14px 54px;
        border-radius: 15px;
        height: 48px;
      }
    }
  }
  .Latest-realeses {
    .v-card__title {
      font-size: 17px;
    }
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .homeSlider {
    .v-window {
      height: 50vh !important;
      .v-image {
        height: 50vh !important;
      }
    }
  }
  .welcome-sec {
    padding-top: 0px;
    .wc-head {
      border-right: none;
      border-bottom: 2px solid #ed1c24;
      text-align: left;
    }
    h1 {
      font-size: 24px;
      line-height: 40px;
    }
    p {
      font-size: 14px;
      word-spacing: 0.5px;
    }
  }
  .gallery {
    padding-top: 50px;
  }
  .Latest-realeses {
    padding-top: 50px;
  }
}
</style>
