import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import VueYoutube from "vue-youtube";
// import "vue-pdf-app/dist/icons/main.css";
Vue.config.productionTip = false;
Vue.use(VueYoutube);
new Vue({
  vuetify,
  router,
  render: (h) => h(App),
}).$mount("#app");
