<template>
 <v-footer>
    <v-container>
     <v-row>
       <v-col md="3" class="text-left" cols="12">
         <div class="footer-text">
          <h1>Best Holdings Limited</h1>
          <v-icon>mdi-map-marker-outline</v-icon>
          <span>49, Kazi Benzir Sarani</span> <br/>
         <span>Nikunja-2, Khilkhet, Dhaka-1229</span><br/>
         <span>    +880-2-8900095, 8900096</span>
         </div>
       </v-col>
       <v-col md="3" lg="3" cols="12" offset-lg="1" class="footer-midle">
         <v-list >
           <v-list-item class="white--text"><strong>Visit</strong></v-list-item>
           <v-list-item class="white--text" style="cursor: pointer;"
     
           
           router :to="item.route"
           v-for="(item,i) in items" :key="i">{{item.name}}</v-list-item>
         </v-list>
       </v-col>
       <v-col lg="5" md="4" cols="12" class="footer-right text-left">
         <h3 class="text-left mb-3">Stay connected</h3>
         <v-item-group class="d-flex">
             <v-text-field
            label="Enter your email"
            placeholder="Enter your email"
            solo
            height="50px"
          ></v-text-field>
             <v-btn
            color="#ED1C24"
            height="50px"
            style="margin-left:-5px"
          >
            Subscribe
          </v-btn>
         </v-item-group>
         <a   v-for="(icon,i) in icons" :key="i" :href="icon.link"><v-icon class="white rounded-circle py-1 px-1 ml-2" size="30" 
           :color="icon.color">{{icon.name}}</v-icon></a>
         </v-col>
     </v-row>
    </v-container>
      <div  style="background:rgba(255,255,255,0.27); width:100%;margin-top:20px;">
        <v-container>
          <v-row>
            <v-col cols="12" class="d-lg-flex py-2 ">
              <p>&copy; 2022 Best Holdings Ltd. All rights reserved</p>
        <v-spacer></v-spacer>
        <p>Design and Developed by <a href="https://dmarkcy.com/" class="white--text">dMarkcy</a></p>
            </v-col>
          </v-row>
        </v-container>
      </div>
 </v-footer>
</template>
<script>
export default {
  data(){
    return{
      items:[
        {name:"Home",route:"/"},
        {name:"Units & Concerns",route:"/units-concerns"},
        {name:"Investor Information",route:"/investor-information"},
        {name:"CSR",route:"/csr"},
      ],
      icons:[
        {name:"mdi-facebook",color:"#4267B2",link:'https://www.facebook.com/bestholdings'},
        {name:"mdi-twitter",color:"#1DA1F2",link:'https://twitter.com/bhlbd'},
        {name:"mdi-youtube",color:"#FF0000",link:'https://www.youtube.com/channel/UCzVExQsx2lx4yJa5BIouDHg'},
        // {name:"mdi-linkedin",color:"#0072b1",link:'/'},
        // {name:"mdi-instagram",color:"#FF0000",link:'/'},
      ]
    }
  }
};
</script>

<style lang="scss">
.v-footer{
    margin-top:150px;
    padding: 60px 0px 0px 0px !important;
    background-color:#181818 !important;
    .v-list-item--active{
      background:transparent !important;
    }
    h1,p,.v-icon,span{
      color: #fff;
    }
   .v-icon{
     font-size: 20px;
    }
    span{
      margin-left: 10px;
    }
    .theme--light.v-sheet{
      background-color: transparent;
    }
  .v-input{
          background:transparent;
  }
  .footer-midle{
    strong{
      border-left: 2px solid #ED1C24;
      padding-left: 5px;
      padding-top:5px;
      padding-bottom:5px;
    }
  }
  .footer-right{
    h3{
      font-size: 25px;
      line-height: 37px;
      border-left: 2px solid #ED1C24;
      padding-left: 5px;
      color:#fff;
      font-weight:600;
    }
    p{
      font-size: 16px;
      line-height: 27px;
      padding-top:24px;

    }
  }
    
 
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .v-footer{
    margin-top:50px;
    padding: 20px 0px !important;
    .footer-midle{
    strong{
      border-left:none;
      padding-left:0px;
    }
    .v-list-item {
      padding:0px;
      padding-left: 10px;
    }
  }
  .footer-right{
    h3{
      font-size: 25px;
      line-height: 37px;
      border-left:none;
      padding-left:0px;
    }
  }
  }
}
</style>