<template>
  <v-app>
      <AppBar/>
    <v-content>
    <router-view/>
    </v-content>
    <FooterBar/>
  </v-app>
</template>

<script>
import AppBar from '@/components/AppBar'
// import SystemBar from '@/components/SystemBar.vue'
import FooterBar from '@/components/FooterBar.vue'
  export default {
    name:'App',
  components:{ AppBar,FooterBar },
    data(){
      return {
        
      }
    }
  }
</script>

<style lang="scss">

#app {
  text-align: center;
}
a{
  text-decoration:none;
}
</style>
